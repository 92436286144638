import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import Modal from 'react-modal';

Modal.setAppElement('#root');

const products = [
  {
    code: 'AA00101',
    title: '(~10/31) [한강 패들보드 예약수 1등] 인생샷 찍어주는 연극영화과 출신 강사진의 패들보드 강습',
    promotion: '(Splash에서만) 기간제 특가',
    discount: '20',
    originalPrice: '50,000원',
    discountedPrice: '39,800원',
    tags: ['패들보드', '인생샷', '한강', '친구'],
    imageUrl: 'img/product/paddleboard_main.jpg'
  },
  {
    code: 'CY00101',
    title: `(~10/31) [11번가, 쿠팡, 네이버 전곡항 요트투어 1등 업체] 초특가 요트 + 갯벌 패키지`,
    promotion: '기간제 특가',
    discount: '37',
    originalPrice: '40,000원',
    discountedPrice: '25,000원',
    tags: ['요트', '단체', '가족', '수도권'],
    imageUrl: 'img/product/cy001/yacht_1.png'
  },
  {
    code: 'CY00102',
    title: '(~10/31) [11번가, 쿠팡, 네이버 전곡항 요트투어 1등 업체] 프리미엄 요트 대여 (41인승)',
    promotion: '기간 한정 프리미엄 오픈',
    discount: '0',
    originalPrice: '1,000,000원',
    discountedPrice: '1,000,000원',
    tags: ['요트', '가족', '연인', '수도권', '단체'],
    imageUrl: 'img/product/cy001/yacht_2.png'
  },
  {
    code: 'AA00102',
    title: '(~10/31) [한강 패들보드 예약수 1등] 한강 뚝섬 자유 카약/패들보드',
    promotion: '특별 상품',
    discount: '0',
    originalPrice: '30,000원',
    discountedPrice: '30,000원',
    tags: ['패들보드', '카약', '한강', '가족'],
    imageUrl: 'img/product/aa001/aa00102_main_1.png'
  }
  // {
  //   code: 'BB00101',
  //   title: '(~9/30) 대학생 빠지 슈퍼할인 프로모션',
  //   promotion: '기간제 특가',
  //   discount: '20',
  //   originalPrice: '30,000원',
  //   discountedPrice: '24,000원',
  //   tags: ['빠지', '대학생', '친구', '단체'],
  //   imageUrl: 'img/product/product_image_3.png'
  // },
];

const DiscontinuedProducts = [
  {
    code: 'BB00101',
    title: '(~9/30) 대학생 빠지 슈퍼할인 프로모션',
    tags: ['빠지', '대학생', '친구', '단체'],
    imageUrl: 'img/product/product_image_3.png'
  }
]

const comingSoonProducts = [
  {
    code: 'AY00101',
    title: '잠실 한강 프라이빗 요트 파티',
    tags: ['요트', '가족', '한강', '파티'],
    imageUrl: 'img/product/yacht_thumb.jpg'
  }
];

const newsData = [
  {
    title: "(딱 1주일만!!) 매일10명 올리브영 상품권 5000원 증정! - Spl...",
    imageUrl: "/img/news/news4.jpg", 
    url: "https://blog.naver.com/splash2024/223572645493",
    sourceType: "Blog",
  },
  {
    title: "🏆Splash 패키지 체험단 모집🏆",
    imageUrl: "/img/news/news3.jpg", 
    url: "https://www.instagram.com/p/C_fLFBlPsc4/",
    sourceType: "Instagram",
  },
  {
    title: "요트투어 + 갯벌체험 패키지를 25,000원에? 전곡항 프리...",
    imageUrl: "/img/news/news2.png", 
    url: "https://blog.naver.com/splash2024/223564291738",
    sourceType: "Blog",
  },
  {
    title: "[대학생 프로모션] 가평 빠지 수상레저를 반값에 갈 수 있다고?...",
    imageUrl: "/img/news/news1.png", 
    url: "https://blog.naver.com/splash2024/223478586496",
    sourceType: "Blog",
  },
];
const ProductsContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: 380px) {
    padding: 10px 0;
  }

  @media (max-width: 480px) {
    padding: 10px 0;
  }
`;

const TitleSection = styled.div`
  padding: 40px 5vw;
  height: 200px;
  background-color: #2B3674;
  display: flex;
  align-items: center;

  h1 {
    font-size: 74px;
    margin: 0;
    color: #fff;
  }

  @media (max-width: 768px) {
    height: 150px;
    h1 {
      font-size: 50px;
    }
  }

  @media (max-width: 480px) {
    height: auto;
    padding: 20px 5vw;
    h1 {
      font-size: 38px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 40px 20px;
  padding: 0 10%;
  margin-top: 50px;
  margin-bottom: 100px;
  justify-items: center;
  max-width: 100%;

  @media (max-width: 768px) {
    padding: 0 5%;
    gap: 30px 15px;
  }

  @media (max-width: 480px) {
    padding: 0 5%;
    gap: 20px 10px;
    margin-bottom: 50px;
  }
`;

const ProductCard = styled.div`
  width: 290px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  .promotion, .original-price {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
  }

  .promotion {
    color: #1B56ED;
    font-weight: bold;
  }

  .original-price {
    color: #616AAD;
    font-weight: 500;
    text-decoration: line-through;
  }

  .discount {
    font-size: 24px;
    font-weight: bold;
    color: #666;
    display: inline-block;
    margin-right: 10px;
  }

  .discounted-price {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    display: inline-block;
    margin-right: 10px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 5px 0;
    color: #000000;
    line-height: 1.2;
  }

  .tags {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    div {
      font-size: 14px;
      font-weight: 500;
      color: #7B7B7B;
      border: 1px solid #D9D9D9;
      border-radius: 44px;
      padding: 2px 8px;
    }
  }

  @media (max-width: 768px) {
    width: 70%;
  }

`;

const DiscontinuedCard = styled(ProductCard)`
  pointer-events: none;
  opacity: 0.6;

  .image-wrapper {
    position: relative;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .discontinued-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`;

const ComingSoonCard = styled(ProductCard)`
  pointer-events: none;
  opacity: 0.6;

  .image-wrapper {
    position: relative;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .coming-soon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`;

const NewsSection = styled.section`
  padding: 60px 10vw;
  background-color: #F7F7F7;
  border-top: 2px solid #D9D9D9;
`;

const NewsTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
`;

const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 40px;
  justify-items: center;
`;

const NewsCard = styled.a`
  display: flex;
  flex-direction: column;
  width: 290px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  h3 {
    font-size: 18px;
    color: #000;
    margin: 15px;
    font-weight: 600;
    line-height: 1.4;
  }

  .sourceType {
    font-size: 14px;
    font-weight: 500;
    color: #7B7B7B;
    margin: 0 15px 15px;
  }
`;

const StyledModal = styled(Modal)`
  position: fixed;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-width: 600px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: hidden;
  outline: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 1050;
  pointer-events: none;

  @media (max-width: 768px) {
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ModalContent = styled.div`
  padding: 3px;
  pointer-events: auto;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const CloseLink = styled.a`
  display: block;
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
  color: black;
  &:hover {
    text-decoration: underline;
  }
  pointer-events: auto;
`;

const RedirectButton = styled.button`
  background-color: #090f85;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
  &:hover {
    background-color: #0056b3;
  }
`;

function Products() {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const modalClosedTime = localStorage.getItem('modalClosedTime');
    if (!modalClosedTime || new Date().getTime() - modalClosedTime > 24 * 60 * 60 * 1000) {
      setModalIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDoNotShowAgain = () => {
    closeModal();
    localStorage.setItem('modalClosedTime', new Date().getTime());
  };

  const goToTargetPage = () => {
    navigate('/products/CY00101');
  };

  return (
    <>
      <Helmet>
        <title>Splash 수상 액티비티 예약 - 기간 한정 특별 할인!</title>
        <meta name="description" content="Splash에서 이번 달만 이 가격에 엄청난 할인 받으세요! 수상레저 센터들과 특별 계약 프로모션을 맺은 할인 상품들 둘러보기!" />
        <meta name="keywords" content="Splash, 스플래시, products, 예약, 구매, 빠지, 요트, 가평, 전곡항, 요트대여, 요트투어, 갯벌, 패키지, 가평빠지, 가평MT, 대학생MT, 대학생놀거리, 놀거리, 대성리, 수상레저, 해양레저, 수상레져, 해양레져, 수상스포츠, 해양스포츠, 수상레저스포츠, 해양레저스포츠, 물놀이, 스포츠, water, 스키장, 수상스키, 웨이크보드, 카누, 서핑" />
        <meta name="author" content="Splash" />
        <meta property="og:type" content="website" />
        <meta property='og:site_name' content='Splash 수상레저 예약' />
        <meta property='og:url' content='https://www.splashreservation.com/' />
        <meta property='og:locale' content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title' content='최고의 수상 액티비티를 가성비 있게 - Splash' />
        <meta property="og:description" content="Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta property='og:image' content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="최고의 수상 액티비티를 가성비 있게 - Splash" />
        <meta name="twitter:description" content="Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/" />
      </Helmet>
      <ProductsContainer>
        <TitleSection>
          <h1>Splash 특별계약 프로모션관</h1>
        </TitleSection>

        <ContentWrapper>
          {products.map(product => (
            <ProductCard key={product.code}>
              <Link to={`/products/${product.code}`}>
                <img src={product.imageUrl} alt={product.title} />
                <div>
                  <span className="promotion">{product.promotion}</span>
                  {product.discount > 0 && (
                    <span className="original-price">{product.originalPrice}</span>
                  )}
                </div>
                <div>
                  {product.discount > 0 && (
                    <span className="discount">-{product.discount}%</span>
                  )}
                  <span className="discounted-price">{product.discountedPrice}</span>
                </div>
                <h2>{product.title}</h2>
                <div className="tags">
                  {product.tags.map(tag => (
                    <div key={tag}>{tag}</div>
                  ))}
                </div>
              </Link>
            </ProductCard>
          ))}

          {DiscontinuedProducts.map(product => (
            <DiscontinuedCard key={product.code}>
              <div className="image-wrapper">
                <img src={product.imageUrl} alt={product.title} />
                <div className="image-overlay"></div>
                <div className="discontinued-text">종료된 이벤트</div>
              </div>
              <h2>{product.title}</h2>
              <div className="tags">
                {product.tags.map(tag => (
                  <div key={tag}>{tag}</div>
                ))}
              </div>
            </DiscontinuedCard>
          ))}

          {comingSoonProducts.map(product => (
            <ComingSoonCard key={product.code}>
              <div className="image-wrapper">
                <img src={product.imageUrl} alt={product.title} />
                <div className="image-overlay"></div>
                <div className="coming-soon-text">Coming Soon</div>
              </div>
              <h2>{product.title}</h2>
              <div className="tags">
                {product.tags.map(tag => (
                  <div key={tag}>{tag}</div>
                ))}
              </div>
            </ComingSoonCard>
          ))}
        </ContentWrapper>
      </ProductsContainer>

    <NewsSection>
        <NewsTitle>최근 소식</NewsTitle>
        <NewsWrapper>
          {newsData.map((news, index) => (
            <NewsCard href={news.url} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={news.imageUrl} alt={news.title} />
              <h3>{news.title}</h3>
              <div className="sourceType">{news.sourceType}</div>
            </NewsCard>
          ))}
        </NewsWrapper>
      </NewsSection>
    </>
  );
}


export default Products;
