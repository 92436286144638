import React, { useState } from 'react';
import styled from 'styled-components';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './pages/Main';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import ProductDetailBB00101 from './pages/ProductDetailBB00101';
import ProductDetailCY00101 from './pages/ProductDetailCY00101';
import ProductDetailCY00102 from './pages/ProductDetailCY00102';
import ProductDetailAA00101 from './pages/ProductDetailAA00101';
import ProductDetailAA00102 from './pages/ProductDetailAA00102';
import Contact from './pages/Contact';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFail from './pages/PaymentFail';

const AppWrapper = styled.div`
  max-width: 100vw; /* Prevent horizontal overflow */
  overflow-x: hidden; /* Prevent horizontal scroll */

  .pagecontent {
    transition: margin-top 0.3s;
  }

  @media (max-width: 768px) {
    &.menu-open .pagecontent {
      margin-top: 200px; /* Adjust based on the height of the opened menu */
    }
  }
`;

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Router>
      <AppWrapper className={menuOpen ? 'menu-open' : ''}>
        <Header setMenuOpen={setMenuOpen} />
        <div className="pagecontent">
          <Routes>
            <Route path="/" exact element={<Products />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/BB00101" element={<ProductDetailBB00101 />} />
            <Route path="/products/CY00101" element={<ProductDetailCY00101 />} />
            <Route path="/products/CY00102" element={<ProductDetailCY00102 />} />
            <Route path="/products/AA00101" element={<ProductDetailAA00101 />} />
            <Route path="/products/AA00102" element={<ProductDetailAA00102 />} />
            {/* <Route path="/products/:productCode" element={<ProductDetail />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-fail" element={<PaymentFail />} />
          </Routes>
        </div>
        <Footer />
      </AppWrapper>
    </Router>
  );
}

export default App;
