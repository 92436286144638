import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #000;
  color: #888;
  font-size: 11px;
  bottom: 0;
  width: 100vw;
  padding: 20px 0 20px 20px;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
`;

function Footer() {
  return (
    <FooterWrapper>
      <p>&copy; 2024 Splash, Bannangco Team</p>
      <p>스플래쉬 &nbsp; 사업자등록번호: 180-03-03134 &nbsp; 대표자: 김태완 &nbsp; Tel: 010-3058-9790 &nbsp; <br/> E-Mail: splashgo2024@gmail.com &nbsp; Location: 경기도 수원시 장안구 서부로 2066, 23동 1층 1호 23101호(천천동, 제 1공학관)</p>
    </FooterWrapper>
  );
}

export default Footer;
